<template>
  <div>
    <div id="custom_card" class="card sticky">
      <div class="grid justify-content-between">
        <div class="col-12 md:col-6">
          <div
            class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
          >
            <p style="color: #6b719b; font-size: 20px; font-weight: 800">
              Dashboard
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
        <div class="col-12 lg:col-3 xl:col-3 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/agents')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Total Agent</span>
                <div class=" text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ total_agent }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round "
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-shopping-cart text-blue-500 text-5xl"></i>
              </div>
            </div>
          
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/categories')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Total Categories</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ total_categories }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-map-marker text-orange-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom"> 
          <div class="card mb-0" id="custom_card_shadow"  @click="go('/tickets')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Open Tickets</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ open_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-inbox text-cyan-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/tickets')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Closed Tickets</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ closed_tickets  }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-comment text-purple-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
      </div>



    <div class="grid">
      <!-- <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #8396b1"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Total Agents
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ total_agent }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-refresh text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #00b0b0"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Total Categories
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ total_categories }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-pencil text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_user_rider()">
          <div
            class="card mb-0"
            style="background-color: #a3cc88"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Open Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ open_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-bookmark text-blue-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #f8c100"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Closed Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ closed_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-times text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      

      <div class="col-12 lg:col-12 xl:col-12">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-3 md:col-3" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                  New vs Closed Ticket
                </h4>
              </div>
              <div class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Dropdown
                    v-model="agent_selected"
                    :options="agent_dropdownItems"
                    :value="agent_dropdownItems._id"
                    optionLabel="name"
                    filter
                    @change="GetTicketByDateDashboard()"
                  >
                  </Dropdown>
                  <label for="name">Select Agent</label>
                </span>
              </div>
              <div class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Dropdown
                    id="Category"
                    v-model="category_selected"
                    :options="category_dropdown"
                    :value="category_dropdown"
                    optionLabel="name"
                    filter
                    @change="GetTicketByDateDashboard()"
                  ></Dropdown>
                  <label for="Category">Select Category</label>
                </span>
              </div>
              <div class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Calendar
                    dateFormat="dd-mm-yy"
                    inputId="calendar"
                    v-model="dates"
                    selectionMode="range"
                    :manualInput="false"
                  ></Calendar>
                  <label for="calendar">Calendar</label>
                </span>
              </div>
              <div class="field col-12 md:col-12">
                <Chart type="line" :data="chartData" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-6">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-4 md:col-4" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                  Tickets By Agents
                </h4>
              </div>
              <div class="field col-8 md:col-8">
                <SelectButton
                  v-model="AgentselectButtonValue"
                  :options="AgentselectButtonValues"
                  optionLabel="name"
                  class="custom-select-button"
                />
              </div>
              <div class="field col-12 md:col-12">
                <div class="flex-container">
                  <Chart
                    type="pie"
                    :data="agentPieData"
                    :options="pieOptions"
                    class="w-full md:w-25rem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-6">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-4 md:col-4" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                  Tickets By Categories
                </h4>
              </div>
              <div class="field col-8 md:col-8">
                <SelectButton
                  v-model="selectButtonValue"
                  :options="selectButtonValues"
                  optionLabel="name"
                  class="custom-select-button"
                />
              </div>
              <div class="field col-12 md:col-12">
                <div class="flex-container">
                  <Chart
                    type="pie"
                    :data="pieData"
                    :options="pieOptions"
                    class="w-full md:w-25rem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>
    <script>
import EventBus from "@/AppEventBus";
import ProductService from "../service/ProductService";
import apis from "@/apis";
import axios from "axios";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";
import superset_dashboard from "../components/superset_dashboard";
import DashboardComponent from "../components/DashboardComponent";
export default {
  data() {
    return {
      chartData: null,
      chartOptions: { aspectRatio: 3.0 },
      dates: null,
      category_dropdown: [],
      category_selected: "",
      category_id: "",
      agent_dropdownItems: [],
      agent_selected: "",
      agent_id: "",
      tabs: ["Dashboard"],
      selectedTab: "Dashboard",
      open_tickets: 0,
      closed_tickets: 0,
      inprogress_tickets: 0,
      isLoadingModel: false,
      fullPage: false,
      total_categories: 0,
      total_agent: 0,
      pieData: {},
      agentPieData: {},
      pieOptions: null,
      CategoriesData: [],
      AgentData: [],
      selectButtonValues: [],
      selectButtonValue: null,
      AgentselectButtonValues: [],
      AgentselectButtonValue: null,
    };
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {},
  async created() {
    window.Buffer = window.Buffer || Buffer;
    this.productService = new ProductService();
    this.user_key = localStorage.getItem("user_key");
    await this.DashboardData();
    await this.CategoriesDashboard();
    await this.AgentDashboard();
    await this.getAgentMaster();
    await this.getTicketCategoryMaster();
    this.dates = await this.getCurrentMonthStartAndEnd();
    await this.GetTicketByDateDashboard();
  },
  watch: {
    async dates() {
      this.GetTicketByDateDashboard();
    },
    async selectedTab() {
      if (this.selectedTab == "Dashboard") {
        await this.DashboardData();
        await this.CategoriesDashboard();
        await this.AgentDashboard();
        this.dates = await this.getCurrentMonthStartAndEnd();
        await this.GetTicketByDateDashboard();
      }
    },
    selectButtonValue: function (newVal, Oldval) {
      if (newVal == null) {
        newVal = Oldval;
        this.selectButtonValue = { name: newVal.name, code: newVal.code };
      }
      if (this.selectButtonValue != null && newVal.name && newVal.code) {
        for (let index = 0; index < this.CategoriesData.length; index++) {
          if (newVal.name == this.CategoriesData[index].name) {
            this.PieData(this.CategoriesData[index]);
          }
        }
      }
    },
    AgentselectButtonValue: function (newVal, Oldval) {
      if (newVal == null) {
        newVal = Oldval;
        this.AgentselectButtonValue = { name: newVal.name, code: newVal.code };
      }
      if (this.AgentselectButtonValue != null && newVal.name && newVal.code) {
        for (let index = 0; index < this.AgentData.length; index++) {
          if (newVal.name == this.AgentData[index].name) {
            this.AgentPieData(this.AgentData[index]);
          }
        }
      }
    },
  },
  methods: {
    go(path)
        {
            this.$router.push(path);
            
        },
    dateFormatYMD(inputDate) {
      var postdate = inputDate;
      if (inputDate) {
        inputDate = new Date(inputDate);
        if (inputDate.toString() == "NaN-NaN-NaN") {
          return postdate;
        } else {
          let date = ("0" + inputDate.getDate()).slice(-2);
          // current month
          let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
          // current year
          let year = inputDate.getFullYear();
          if (date.toString() == "aN") {
            return postdate;
          } else {
            return year + "-" + month + "-" + date;
          }
        }
      } else {
        return "";
      }
    },
    async getCurrentMonthStartAndEnd() {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      // Format dates as strings in the specified format
      const startOfMonthString = startOfMonth.toISOString();
      const endOfMonthString = endOfMonth.toISOString();
      return [new Date(startOfMonthString), new Date(endOfMonthString)];
    },
    async getAgentMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      var promise = apis.agentListDropdown(data);
      promise.then((response) => {
        var temp = [];
        for (let index = 0; index < response.data.data.length; index++) {
          temp.push({
            _id: response.data.data[index].agent_id,
            name: response.data.data[index].agent_name,
          });
        }
        this.agent_dropdownItems = temp;
      });
    },
    async getTicketCategoryMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      this.isLoadingModel = true;
      var promise = apis.TicketCategoryMaster(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.category_dropdown = response.data.data;
      });
    },
    async DashboardData() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      this.isLoadingModel = true;
      var promise = apis.AdminDashboardData(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        var temp = response.data.data;
        this.total_categories = response.data.total_categories
          ? response.data.total_categories
          : 0;
        this.total_agent = response.data.total_agents
          ? response.data.total_agents
          : 0;
        for (let index = 0; index < temp.length; index++) {
          const element = temp[index];
          if (element.name == "Open") {
            this.open_tickets = element.total;
          }
          if (element.name == "In Progress") {
            this.inprogress_tickets = element.total;
          }
          if (element.name == "Closed") {
            this.closed_tickets = element.total;
          }
        }
      });
    },
    async CategoriesDashboard() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      this.isLoadingModel = true;
      var promise = apis.ticketByCategoriesDashboard(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.CategoriesData = response.data.data;
        for (let index = 0; index < this.CategoriesData.length; index++) {
          this.selectButtonValues.push({
            name: this.CategoriesData[index].name,
            code: this.CategoriesData[index].sequence_number,
          });
        }
        this.selectButtonValue = {
          name: this.CategoriesData[0].name,
          code: this.CategoriesData[0].sequence_number,
        };
        this.PieData(this.CategoriesData[0]);
      });
    },
    async AgentDashboard() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      this.isLoadingModel = true;
      var promise = apis.ticketByAgentAdminDashboard(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.AgentData = response.data.message;
        for (let index = 0; index < this.AgentData.length; index++) {
          this.AgentselectButtonValues.push({
            name: this.AgentData[index].name,
            code: this.AgentData[index].sequence_number,
          });
        }
        this.AgentselectButtonValue = {
          name: this.AgentData[0].name,
          code: this.AgentData[0].sequence_number,
        };
        this.AgentPieData(this.AgentData[0]);
      });
    },
    async GetTicketByDateDashboard() {
      this.chartData = null;
      //get category id
      this.category_id = this.category_selected._id;
      // get agent type
      this.agent_id = this.agent_selected._id;
      var from_date = this.dateFormatYMD(this.dates[0]);
      var to_date = this.dateFormatYMD(this.dates[1]);
      var data = {
        from_date: from_date,
        to_date: to_date,
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        agent_id: this.agent_id,
        category_id: this.category_id,
      };
      var promise = apis.ticketByDateDashboard(data);
      promise.then((response) => {
        var data = response.data.data[0];
        this.ChartData(data);
      });
    },
    async ChartData(data) {
      this.chartData = {
        labels: data.date,
        datasets: [
          {
            label: "Open Ticket",
            data: data.open_ticket,
            fill: false,
            borderColor: "#EC4899",
            tension: 0.4,
          },
          {
            label: "Inprogress Ticket",
            data: data.inprogress_ticket,
            fill: false,
            borderColor: "#3B82F6",
            tension: 0.4,
          },
          {
            label: "Close Ticket",
            data: data.close_ticket,
            fill: false,
            borderColor: "#F7941E",
            tension: 0.4,
          },
        ],
      };
    },
    async PieData(data) {
      console.log(data);
      this.pieData = {
        labels: data.labels,
        datasets: [
          {
            data: data.series,
            backgroundColor: [
              "#F04136",
              "#2B3890",
              "#F7941E",
              "#39B54A",
              "#D7F422",
              "#DE6BBC",
              "#DEDD59",
              "#58DEDF",
            ],
            // hoverBackgroundColor: [
            //   "#FF6384",
            //   "#36A2EB",
            //   "#FFCE56",
            //   "#662D91",
            //   "#F8F194",
            //   "#58DEDF",
            //   "#949597",
            //   "#DEDD59",
            // ],
          },
        ],
      };
    },
    async AgentPieData(data) {
      this.agentPieData = {
        labels: data.label,
        datasets: [
          {
            data: data.series,
            backgroundColor: [
              "#F04136",
              "#2B3890",
              "#F7941E",
              "#39B54A",
              "#D7F422",
              "#DE6BBC",
              "#DEDD59",
              "#58DEDF",
            ],
            // hoverBackgroundColor: [
            //   "#FF6384",
            //   "#36A2EB",
            //   "#FFCE56",
            //   "#662D91",
            //   "#F8F194",
            //   "#58DEDF",
            //   "#949597",
            //   "#DEDD59",
            // ],
          },
        ],
      };
    },
  },
  components: {
    Tabs,
    Tab,
    TabPanels,
    superset_dashboard,
    DashboardComponent,
  },
};
</script>
    <style>
</style>
    <style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
}
.p-button-sm {
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
}
.active {
  border-bottom: 4px solid #274461;
  color: black;
  font-weight: bold;
}
.tab:hover {
  font-weight: bold;
  border-bottom: 4px solid #274461;
}
.inactive {
  color: black;
}
.card {
  cursor: pointer;
}
#custom_card {
  border-top: 3px solid #6b719b;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
#custom_card_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}
.flex-container {
  display: flex;
  justify-content: center;
}
.flex-container > div {
  text-align: center;
}
</style>